<template>
  <div class="yf_Door">
    <div class="mask-box mask-left" ref="maskLeft">
      <router-link to="/">ME</router-link>
    </div>
    <div class="mask-box mask-right" ref="maskRight">
      <a href="http://yunfei.ltd" target="_blank">STUDY</a>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
export default {
  name: "Door",
  setup() {
    const maskLeft = ref();
    const maskRight = ref();
    onMounted(() => {
      // PC端
      maskLeft.value.addEventListener("mouseenter", mouseenter);
      maskLeft.value.addEventListener("mouseleave", moveleave);

      maskRight.value.addEventListener("mouseenter", mouseenter);
      maskRight.value.addEventListener("mouseleave", moveleave);

      // 移动端
      maskLeft.value.addEventListener("touchstart", mouseenter);
      maskLeft.value.addEventListener("touchend", moveleave);

      maskRight.value.addEventListener("touchstart", mouseenter);
      maskRight.value.addEventListener("touchend", moveleave);

      // 点击事件
    });
    // 移入事件
    function mouseenter(e) {
      e.target.classList.add("mask-box-active");
      // e.preventDefault();
    }
    // 移出事件
    function moveleave(e) {
      e.target.classList.remove("mask-box-active");
    }
    // 挂载事件
    onBeforeUnmount(() => {
      // PC端]
      maskLeft.value.removeEventListener("mouseenter", mouseenter);
      maskLeft.value.removeEventListener("mouseleave", moveleave);

      maskRight.value.removeEventListener("mouseenter", mouseenter);
      maskRight.value.removeEventListener("mouseleave", moveleave);

      // 移动端
      maskLeft.value.removeEventListener("touchstart", mouseenter);
      maskLeft.value.removeEventListener("touchend", moveleave);

      maskRight.value.removeEventListener("touchstart", mouseenter);
      maskRight.value.removeEventListener("touchend", moveleave);
    });
    return {
      maskLeft,
      maskRight,
    };
  },
};
</script>
<style >
.yf_Door {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("../assets/img/home.jpg");
  background-position: top center;
  background-size: cover;
}
/* 遮罩 */
.mask-box {
  display: flex;
  position: absolute;
  top: 0;
  width: 50vw;
  height: 100vh;
  transition: background-color 1s;
}
.mask-box a {
  margin: auto;
  display: none;
  width: 20vw;
  height: 20vw;
  max-width: 100px;
  max-height: 100px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  line-height: 20vw;
  font-size: 16px;
  border-radius: 50%;
  background: radial-gradient(
    ellipse at center,
    rgba(29, 71, 191, 0.2) 50%,
    rgba(233, 230, 230, 0.7) 100%
  );
  animation: ball 3s infinite;
}
.mask-box a:hover {
  background: radial-gradient(
    ellipse at center,
    rgba(29, 189, 230, 0.2) 50%,
    rgba(219, 225, 238, 0.849) 100%
  );
}
.mask-left {
  left: 0;
}
.mask-right {
  right: 0;
}

.mask-box-active {
  background-color: rgba(53, 145, 244, 0.4);
}
.mask-box-active a {
  display: block;
}

@keyframes ball {
  0% {
    transform: translateY(15px);
  }

  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(15px);
  }
}
</style>
